* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --primary-color: #ff6712;
  --grey-color: #a6a6a6;
  --text-color: #000;
  --light-grey: #e1e1e1;
}

@font-face {
  font-family: "OpenSans-Reg";
  src: url("./assets/Open_Sans/OpenSans-Regular.ttf");
}

@font-face {
  font-family: "OpenSans-Bold";
  src: url("./assets/Open_Sans/OpenSans-Bold.ttf");
}
@font-face {
  font-family: "OpenSans-Light";
  src: url("./assets/Open_Sans/OpenSans-Light.ttf");
}

body {
  font-family: "OpenSans-Reg";
}

h1,
h2,
h3,
h4,
h5 {
  font-family: "OpenSans-light";
}

h1 {
  font-size: 2em;
}

h2 {
  font-size: 1.8em;
}

h3 {
  font-size: 1.6em;
}

h4 {
  font-size: 1.2em;
}

h5 {
  font-size: 1em;
}

.links:hover {
  color: var(--primary-color);
}

.custom-form {
  margin: 0 5%;
  padding: 5% 0;
}

.custom-item-form {
  margin: 0 5%;
}

.custom-form-group {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
}

.unsplashContainer {
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
}

.unsplashImg {
  margin: 3% 5%;
  border-radius: 15px;
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
  align-items: center;
  height: 20vh;
  width: 40%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.button-cont {
  display: flex;
  flex-flow: row;
  justify-content: center;
  margin: 5% 0;
}

.uploadImage {
  padding: 2% 5%;
  border: none;
  outline: none;
  background-color: var(--primary-color);
  color: white;
  border-radius: 5px;
}

.icons-right {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  width: 20%;
}

.binContainer {
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
  width: 100%;
  padding: 5%;
}

.nav-bar-top-custom {
  height: 6vh;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  padding: 0 5%;
  align-items: center;
  background-color: white;
  border-bottom: 1px solid var(--light-grey);
  width: 100%;
  font-family: OpenSans-Reg;
}

.smallText {
  padding: 0;
  margin: 0;
  text-align: center;
}

.nav-bar-top {
  height: 6vh;
  display: flex;
  flex-flow: row;
  justify-content: center;
  padding: 0 5%;
  align-items: center;
  background-color: white;
  border-bottom: 1px solid var(--light-grey);
  width: 100%;
  font-family: OpenSans-Reg;
}

.title-cont {
  padding: 25% 0 0 0;
  margin: 0 5%;
}
.logo {
  width: 30%;
}

.logo:hover {
  cursor: pointer;
}

.nav-web {
  display: none;
  width: 60%;
  justify-content: space-between;
  flex-flow: row-reverse;
}

.top-image-container {
  height: 50vh;
  width: 100%;
  background-position: center;
  background-size: cover;
  border-bottom-right-radius: 20;
  border-bottom-left-radius: 20;
}

.placeholder-image {
  display: flex;
  flex-flow: column;
  background-color: var(--light-grey);
  justify-content: center;
  align-items: center;
  height: 40vh;
}

.navbar {
  width: 5vh;
}

.location-containers {
  display: flex;
  flex-flow: column;
  justify-content: center;
  margin: 5 0%;
}

.tabs {
  padding: 5% 0 15% 0;
}

.tab-item {
  padding: 5% 5%;
}

.item-container {
  margin: 5%;
}

.loadingScreen {
  padding: 40% 0;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.inner-location {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  min-width: 35%;

  align-items: center;
}

.delete-container {
  padding: 1%;
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
  width: 100%;
}

.item-set-cont {
  padding: 4% 5%;
}

.set-desc-cont {
  padding: 0;
  width: 30%;
}

.tag-cont {
  margin: 10% 0;
}

.image-banner {
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
}

.tag {
  margin: 2% 1% 2% 0;
}

.current-categories {
  padding: 0 0 1% 0;
  text-align: center;
  width: 30%;
  margin: 1%;
  border-radius: 10px;
  box-shadow: 0px 1px 2px black;
  display: flex;

  flex-flow: column;
  justify-content: space-between;
  align-items: center;
}

.imageOverlay {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 40vh;
  object-fit: cover;
  text-align: center;
  border-radius: 10px;
  margin-bottom: 10%;
}

.inputContainer {
  margin: 2% 0;
  text-align: center;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
}

.activity-image-container {
  display: flex;
  flex-flow: column;
  height: 80vh;
  justify-content: center;
  align-items: center;
}

.itemText {
  height: 5vh;
}

.current-cat-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
}

.container {
  margin: 1% 5%;
}

.primary-container {
  display: flex;
  flex-flow: row wrap;
  margin: 2% 3%;
  justify-content: space-between;
}

.left-column-free {
  padding: 0% 2%;
  display: flex;
  flex-flow: column;
  width: 60vw;
}

.right-column-fixed {
  position: fixed;
  right: 5%;
  width: 40vh;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  height: 80vh;
  overflow-y: scroll;
  padding: 1%;
}

.right-column-fixed h4 {
  text-align: center;
}

.item-section {
  box-shadow: 0px 1px 5px grey;
  border-radius: 5px;
  padding: 4%;
  margin: 0 0 5% 0;
}

.list-item {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  box-shadow: 0px 1px 5px grey;
  background-color: white;
  border-radius: 5px;
}

.txt {
  width: 90%;
  padding: 2%;
}
.delete {
  padding: 2%;
}

.delete:hover {
  cursor: pointer;
}

.sets-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  width: 100%;
  align-items: flex-start;
}

.set-card {
  min-height: 30vh;
  box-shadow: 0px 2px 8px grey;
  width: 46%;
  border-radius: 10px;
  margin: 2% 0;
  background-position: center;
  background-size: cover;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  padding: 3% 4%;
  color: white;
  transition: all 0.2s ease-in-out;
  user-select: none;
}

.set-card:hover {
  cursor: pointer;
  box-shadow: 0px 5px 8px black;
}

.button-group {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  width: 100%;
  justify-content: space-evenly;
}

.button-discover {
  padding: 2% 4%;
  border: none;
  background-color: var(--primary-color);
  border-radius: 10px;
  color: white;
  font-size: 1em;
  margin: 1%;
}

.discover-daily-p {
  margin: 0 0 1% 0;
  padding: 5% 0 0 0;
  font-weight: bold;
}

.cstm-line {
  border-color: var(--light-grey);
}
.discover-week-picker {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: baseline;
  margin: 0 0 5% 0;
}

.button-discover:disabled {
  background-color: #505050;
  cursor: not-allowed;
}

.button-discover:disabled:hover {
  background-color: #505050;
  cursor: not-allowed;
}

.button-discover:hover {
  cursor: pointer;
  background-color: #ff5e00;
}

@media screen and (min-width: 750px) {
  .container {
    margin: 5% 30%;
  }
  .nav-web {
    display: flex;
  }
  .custom-form {
    margin: 0 30%;
    padding: 2% 0;
  }

  .title-cont {
    padding: 10% 0 2% 0;
    margin: 0 10%;
  }

  .logo {
    width: 10%;
  }

  .icons-right {
    width: 10%;
  }
  .top-image-container {
    height: 40vh;
  }

  .custom-item-form {
    margin: 0 30%;
  }

  .placeholder-image {
    margin: 0 30%;
  }
  .navbar {
    width: 2vh;
  }

  .location-containers {
    margin: 1% 30%;
  }

  .unsplashImg {
    margin: 1% 20%;
  }

  .tabs {
    padding: 1% 20% 10% 20%;
  }

  .tab-item {
    padding: 1% 1%;
  }

  .item-container {
    margin: 2% 30%;
  }

  .set-desc-cont {
    margin: 0 10%;
    width: 80%;
  }
  .image-banner {
    border-radius: 0;
  }
}

@media screen and (min-width: 900px) {
  .custom-form {
    margin: 0 33%;
    padding: 2% 0;
  }
  .placeholder-image {
    margin: 0 37%;
  }
  .custom-item-form {
    margin: 0 37%;
    padding: 1% 0;
  }

  .location-containers {
    margin: 1% 30%;
  }
  .tabs {
    padding: 1% 25% 10% 25%;
  }

  .item-container {
    margin: 2% 37%;
  }
}

.noset {
  padding: 20% 0;
  display: flex;
  flex-flow: column;
  height: 100vh;
  align-items: center;
  justify-content: center;
}
